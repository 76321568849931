/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.tweets::-webkit-scrollbar,
.youtube::-webkit-scrollbar,
.modal::-webkit-scrollbar {
  width: 1px;
  height: 2px;
  outline: none;
  border: none;
}

.tweets::-webkit-scrollbar-thumb,
.youtube::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}

.tweets::-webkit-scrollbar-track,
.youtube::-webkit-scrollbar-track,
.modal::-webkit-scrollbar-track {
  background-color: rgb(222, 220, 220);
}
.oops {
  display: inline-block;
  margin: 0;
  font-size: 18rem;
  background-image: url("https://cdn.hashnode.com/res/hashnode/image/upload/v1716301215474/6323fa1c-5f4d-4a39-b9eb-5b25e0539162.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

@keyframes stripe {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}

.striped {
  background-image: linear-gradient(
    45deg,
    #fff 25%,
    #fff 25%,
    #fff 50%,
    #fff 50%,
    #fff 75%,
    #fff 75%,
    #fff 100%
  );
  background-size: 40px 40px;
  animation: stripe 2s linear infinite;
}

.progress-bar {
  transition: width 10s ease-in-out;
}
