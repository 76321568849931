@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* @import url("https://fonts.googleapis.com/css?family=Helvetica"); */

/* :root {
  font-family: Helvetica, sans-serif;
} */

body {
  font-family: "Roboto", serif;
}

.title {
  font-family: "Roboto", sans-serif;
  font-weight: bolder;
  font-size: 1.5rem;
}

.hero-section {
  position: relative;
  height: 80vh;
  background-image: url("https://cdn.hashnode.com/res/hashnode/image/upload/v1706437958071/e0b3386c-dce8-473f-9190-4aa8e89b1d10.jpeg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}

.scroll-hidden::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit-based browsers (Chrome, Safari) */
}

.scroll-hidden {
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}
